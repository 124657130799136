import { memo } from 'preact/compat';
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  DirectionsRenderer,
  Marker,
  Polyline,
  Polygon,
  MarkerClusterer,
  KmlLayer,
} from '@react-google-maps/api';
import { useContext, useState } from 'preact/hooks';
import StoreContext from '../../context/store';
import {
  getPlacesToDisplay,
  getDirectionsEdges,
  getDirectionsWaypoints,
} from '../../context/selectors';
import FC_InfoWindow from '../info-window';
import FC_Marker from '../marker';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const areaOptions = {
  strokeColor: '#7b7a70',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: '#72716b',
  fillOpacity: 0.2,
};

const streetOptions = {
  geodesic: true,
  strokeColor: '#316965',
  strokeOpacity: 1.0,
  strokeWeight: 4,
};

const DEF_CENTER = { lat: 45.62, lng: 10.01 };

const handleApiLoaded = (map, state, dispatch, slicedPlaces) => {
  const maps = window.google.maps;

  // salva un riferimento nello store per usi futuri
  dispatch({ type: 'google_map_loaded', payload: { maps, map } });
  if (slicedPlaces && slicedPlaces.length === 1) {
    dispatch({ type: 'marker_click', payload: { activeMarker: slicedPlaces[0] } });
  }
};

const kmlOptions = {
  suppressInfoWindows: true,
  preserveViewport: true,
};

const outerCoords = [
  { lat: -85.1054596961173, lng: -180 },
  { lat: 85.1054596961173, lng: -180 },
  { lat: 85.1054596961173, lng: 180 },
  { lat: -85.1054596961173, lng: 180 },
  { lat: -85.1054596961173, lng: 0 },
];

const getMapCenter = (state, places, userLocation) => {
  if (state.mapCenter) {
    return state.mapCenter;
  }

  if (places && places.length === 1 && typeof places[0] !== 'undefined') {
    const { lat, lng } = places[0];
    return { lat, lng };
  }

  if (userLocation.lat !== null && userLocation.lng !== null) {
    return { lat: userLocation.lat, lng: userLocation.lng };
  }

  return DEF_CENTER;
};

const MapContainer = () => {
  const store = useContext(StoreContext);
  const { state, dispatch } = store;
  const { infoWindowIsOpen, activeMarker, userLocation, visiblePlaces } = state;
  const [directionResult, setDirectionResult] = useState({});
  const placesToDisplay = getPlacesToDisplay(state);
  const slicedPlaces = placesToDisplay.slice(0, visiblePlaces);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCWbSlE9_tctdlX7BKwQnDD7CNikXufAqo',
  });

  return isLoaded
    ? <GoogleMap
      mapContainerStyle={containerStyle}
      center={getMapCenter(state, slicedPlaces, userLocation)}
      zoom={state.ui_map_zoom}
      onLoad={map => handleApiLoaded(map, state, dispatch, slicedPlaces)}
      onUnmount={() => dispatch({ type: 'google_map_unmount' })}
      onClick={() => dispatch({ type: 'map_click' })}
      options={{
        styles: state.ui_map_style_json, zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      }}
      id="examplePolyStyle"
    >

      <MarkerClusterer
        minimumClusterSize={4}
        gridSize={40}
        styles={[
          {
            url: 'https://goat.keyformat.it/static/franciacorta/map/assets/v2/icons/clusterer/clusterer2.png',
            height: 59,
            width: 59,
            textColor: '#FFF',
            textSize: '22',
            fontFamily: 'Prata',
            fontWeight: '400',
          },
        ]}
      >
        {clusterer =>
          slicedPlaces.map(
            (place, index) =>
              place &&
              place.lat &&
              place.lng &&
              <FC_Marker
                place={place}
                position={place}
                key={index}
                clusterer={clusterer}
              />
          )}

      </MarkerClusterer>

      {infoWindowIsOpen
        ? <FC_InfoWindow place={activeMarker} />
        : <FC_InfoWindow />}

      {userLocation.lat != null &&
        userLocation.lng != null &&
        <Marker
          //clusterer={clusterer}
          position={{
            lat: userLocation.lat,
            lng: userLocation.lng,
          }}
        />}

      {state.directionPoints.size > 1 &&
        <div>
          <DirectionsService
            options={{
              origin: getDirectionsEdges(state)[0],
              destination: getDirectionsEdges(state)[1],
              waypoints: getDirectionsWaypoints(state),
              travelMode: 'DRIVING',
            }}
            callback={setDirectionResult}
          />

          <DirectionsRenderer
            directions={directionResult}
            options={{
              polylineOptions: {
                strokeOpacity: 1,
                strokeColor: '#316965',
                strokeWeight: 5,
              },
              suppressInfoWindows: true,
              suppressMarkers: true,
            }}
          />
        </div>}

      {state.path_area &&
        <Polygon
          //visible={state.ui_show_edge}
          visible={true}
          //path={state.path_area}
          paths={[outerCoords, state.path_area]}
          options={areaOptions}
          onClick={() => dispatch({ type: 'map_click' })}
        />}

      {state.path_street &&
        state.path_street &&
        <Polyline
          visible={state.ui_show_fcstreet}
          path={state.path_street}
          options={streetOptions}
        />}

      {state.kml_layer_url &&
        <KmlLayer url={state.kml_layer_url} options={kmlOptions} />}

    </GoogleMap>
    : <div />;
};

export default memo(MapContainer);
